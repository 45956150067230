export const stopPointRefs = {
  inbound: [
    {
      directionIndex: 0,
      name: "Addington Village Interchange",
      atcoCode: "490003101D",
      naptanCode: "48372",
      lat: 51.35657569083617,
      long: -0.032392121832655565,
    },
    {
      directionIndex: 1,
      name: "Addington Park",
      atcoCode: "490003094W",
      naptanCode: "75142",
      lat: 51.35396040041646,
      long: -0.03892406176538876,
    },
    {
      directionIndex: 2,
      name: "Gravel Hill Tram Stop",
      atcoCode: "490002041A",
      naptanCode: "49917",
      lat: 51.35476222681589,
      long: -0.04331343817348631,
    },
    {
      directionIndex: 3,
      name: "Heathfield Farm",
      atcoCode: "490007993N",
      naptanCode: "53677",
      lat: 51.356317659346104,
      long: -0.050845118828438965,
    },
    {
      directionIndex: 4,
      name: "Coombe Lane Tram Stop",
      atcoCode: "490002024A",
      naptanCode: "57644",
      lat: 51.35940923921888,
      long: -0.05559757949010225,
    },
    {
      directionIndex: 5,
      name: "Pine Coombe",
      atcoCode: "490011005N",
      naptanCode: "52328",
      lat: 51.36443882913174,
      long: -0.05196558789812574,
    },
    {
      directionIndex: 6,
      name: "Oaks Road",
      atcoCode: "490010505N",
      naptanCode: "73573",
      lat: 51.366611335082816,
      long: -0.052821552909389446,
    },
    {
      directionIndex: 7,
      name: "Shirley Windmill",
      atcoCode: "490012103N",
      naptanCode: "72604",
      lat: 51.36961638973769,
      long: -0.05453304680305553,
    },
    {
      directionIndex: 8,
      name: "Shirley Church Road",
      atcoCode: "490012094N",
      naptanCode: "75865",
      lat: 51.372778650357965,
      long: -0.05596510862307649,
    },
    {
      directionIndex: 9,
      name: "Trinity School_ Shirley",
      atcoCode: "490012099E2",
      naptanCode: "74424",
      lat: 51.37595646392409,
      long: -0.06213847266435217,
    },
    {
      directionIndex: 10,
      name: "Birch Tree Way",
      atcoCode: "490004014W",
      naptanCode: "75601",
      lat: 51.374882320496255,
      long: -0.06625016216616121,
    },
    {
      directionIndex: 11,
      name: "Mapledale Avenue",
      atcoCode: "490009623W",
      naptanCode: "59433",
      lat: 51.374173404603546,
      long: -0.07015950937111602,
    },
    {
      directionIndex: 12,
      name: "Ashburton Road_ Addiscombe",
      atcoCode: "490003420W2",
      naptanCode: "53345",
      lat: 51.37449189493788,
      long: -0.07421240575906694,
    },
    {
      directionIndex: 13,
      name: "Sandilands Tram Stop",
      atcoCode: "490002072A",
      naptanCode: "51163",
      lat: 51.37508364783934,
      long: -0.07793780144861738,
    },
    {
      directionIndex: 14,
      name: "Canning Road_ Addiscombe",
      atcoCode: "490004747W",
      naptanCode: "47385",
      lat: 51.37501591610271,
      long: -0.08203570814433048,
    },
    {
      directionIndex: 15,
      name: "Lebanon Road Tram Stop",
      atcoCode: "490002052W",
      naptanCode: "77000",
      lat: 51.375020376094255,
      long: -0.08450693145492402,
    },
    {
      directionIndex: 16,
      name: "East Croydon Rail Station",
      atcoCode: "490001089E7",
      naptanCode: "73337",
      lat: 51.37458787772329,
      long: -0.09327541019516383,
    },
    {
      directionIndex: 17,
      name: "Park Street",
      atcoCode: "490010839C3",
      naptanCode: "77597",
      lat: 51.3734855452604,
      long: -0.09793338888727805,
    },
    {
      directionIndex: 18,
      name: "Coombe Road",
      atcoCode: "490005574S",
      naptanCode: "50432",
      lat: 51.36820938558446,
      long: -0.09920078820197692,
    },
    {
      directionIndex: 19,
      name: "Aberdeen Road_ South Croydon",
      atcoCode: "490013269S",
      naptanCode: "56994",
      lat: 51.36589444133644,
      long: -0.09896623311138483,
    },
    {
      directionIndex: 20,
      name: "Swan & Sugar Loaf",
      atcoCode: "490013100J",
      naptanCode: "54210",
      lat: 51.363049877559256,
      long: -0.09823649623016081,
    },
    {
      directionIndex: 21,
      name: "Upland Road_ Croydon",
      atcoCode: "490013920S",
      naptanCode: "50069",
      lat: 51.360075403380215,
      long: -0.09781387102154655,
    },
    {
      directionIndex: 22,
      name: "Haling Park Road",
      atcoCode: "490007670S",
      naptanCode: "74548",
      lat: 51.35694406117701,
      long: -0.0976994174944189,
    },
    {
      directionIndex: 23,
      name: "South Croydon Bus Garage",
      atcoCode: "490015442S",
      naptanCode: "47332",
      lat: 51.35386500606652,
      long: -0.09748227379278118,
    },
    {
      directionIndex: 24,
      name: "Sanderstead Road",
      atcoCode: "490011481S",
      naptanCode: "76072",
      lat: 51.351928169162505,
      long: -0.09838110688372904,
    },
    {
      directionIndex: 25,
      name: "Purley Oaks Station",
      atcoCode: "490013603C",
      naptanCode: "53062",
      lat: 51.349817968444704,
      long: -0.10023484482538315,
    },
    {
      directionIndex: 26,
      name: "Biddulph Road",
      atcoCode: "490003117B",
      naptanCode: "57272",
      lat: 51.34634694133063,
      long: -0.10302057177357256,
    },
    {
      directionIndex: 27,
      name: "Brighton Road / Purley Downs Road",
      atcoCode: "490013543S",
      naptanCode: "54138",
      lat: 51.34403363601966,
      long: -0.10677761894813755,
    },
    {
      directionIndex: 28,
      name: "Montpelier Road",
      atcoCode: "490010002S",
      naptanCode: "76273",
      lat: 51.342034512441685,
      long: -0.11050700360199442,
    },
    {
      directionIndex: 29,
      name: "Purley / Downlands Precinct",
      atcoCode: "490011259G",
      naptanCode: "75325",
      lat: 51.3392847976847,
      long: -0.11565953383239694,
    },
    {
      directionIndex: 30,
      name: "Purley Tesco",
      atcoCode: "490011274T",
      naptanCode: "57036",
      lat: 51.33689180289244,
      long: -0.11839928918622987,
    },
    {
      directionIndex: 31,
      name: "Grovelands Road (CR8)",
      atcoCode: "490007560S",
      naptanCode: "75719",
      lat: 51.33418257629678,
      long: -0.12161106918180897,
    },
    {
      directionIndex: 32,
      name: "Brighton Road / Old Lodge Lane",
      atcoCode: "490010565D",
      naptanCode: "76439",
      lat: 51.3320915421288,
      long: -0.12412255083218825,
    },
    {
      directionIndex: 33,
      name: "Musgrove Close",
      atcoCode: "490014421S",
      naptanCode: "73374",
      lat: 51.329751608133876,
      long: -0.12625637553573235,
    },
    {
      directionIndex: 34,
      name: "Smitham Downs Road",
      atcoCode: "490015438S",
      naptanCode: "50984",
      lat: 51.327728742043696,
      long: -0.1279751637360836,
    },
    {
      directionIndex: 35,
      name: "Windermere Road (CR5)",
      atcoCode: "490012207W1",
      naptanCode: "54989",
      lat: 51.326193662965665,
      long: -0.12983183877133445,
    },
    {
      directionIndex: 36,
      name: "Stoats Nest Village",
      atcoCode: "490012821S",
      naptanCode: "52047",
      lat: 51.32299996466612,
      long: -0.12862698755499985,
    },
    {
      directionIndex: 37,
      name: "Cearn Way",
      atcoCode: "490004901S",
      naptanCode: "50273",
      lat: 51.31909426304521,
      long: -0.1267624411623731,
    },
    {
      directionIndex: 38,
      name: "Coulsdon Court Road",
      atcoCode: "490005639S",
      naptanCode: "49879",
      lat: 51.31470995826252,
      long: -0.12421457984717162,
    },
    {
      directionIndex: 39,
      name: "Oasis Academy Byron",
      atcoCode: "490012862S",
      naptanCode: "53504",
      lat: 51.311892045184116,
      long: -0.12289462065025458,
    },
    {
      directionIndex: 40,
      name: "Old Coulsdon / Tudor Rose",
      atcoCode: "490010535S",
      naptanCode: "57694",
      lat: 51.307374466323445,
      long: -0.12045338572752523,
    },
    {
      directionIndex: 41,
      name: "The Crossways",
      atcoCode: "490013317S2",
      naptanCode: "48521",
      lat: 51.30415302609226,
      long: -0.11864822692787022,
    },
    {
      directionIndex: 42,
      name: "Taunton Lane",
      atcoCode: "490013168S",
      naptanCode: "49771",
      lat: 51.302554969798436,
      long: -0.11825450281529679,
    },
    {
      directionIndex: 43,
      name: "Coulsdon Road / Lacey Drive",
      atcoCode: "490008895S",
      naptanCode: "55790",
      lat: 51.2993003087134,
      long: -0.11606372455433511,
    },
    {
      directionIndex: 44,
      name: "Coulsdon Common / The Fox",
      atcoCode: "490005638S",
      naptanCode: "77964",
      lat: 51.29621306964227,
      long: -0.10864518098907791,
    },
  ],
  outbound: [
    {
      directionIndex: 7,
      name: "Coulsdon Common / The Fox",
      atcoCode: "490005638N",
      naptanCode: "77437",
      lat: 51.296158728022284,
      long: -0.1091781409562989,
    },
    {
      directionIndex: 8,
      name: "Coulsdon Road / Lacey Drive",
      atcoCode: "490008895N",
      naptanCode: "58016",
      lat: 51.29876062258672,
      long: -0.11549768476221808,
    },
    {
      directionIndex: 9,
      name: "Taunton Lane",
      atcoCode: "490013168N",
      naptanCode: "52874",
      lat: 51.30132837130444,
      long: -0.11803210210502102,
    },
    {
      directionIndex: 10,
      name: "The Crossways",
      atcoCode: "490013317N",
      naptanCode: "72004",
      lat: 51.304722196331944,
      long: -0.11881145759400838,
    },
    {
      directionIndex: 11,
      name: "Old Coulsdon / Tudor Rose",
      atcoCode: "490010535N",
      naptanCode: "75189",
      lat: 51.30734473205814,
      long: -0.12084198125400143,
    },
    {
      directionIndex: 12,
      name: "Oasis Academy Byron",
      atcoCode: "490012862N",
      naptanCode: "47602",
      lat: 51.312212563543724,
      long: -0.12325461221282132,
    },
    {
      directionIndex: 13,
      name: "Coulsdon Court Road",
      atcoCode: "490005639N",
      naptanCode: "73138",
      lat: 51.314322371786254,
      long: -0.12415864020468458,
    },
    {
      directionIndex: 14,
      name: "Cearn Way",
      atcoCode: "490004901N",
      naptanCode: "76830",
      lat: 51.319479284104055,
      long: -0.12722034582184835,
    },
    {
      directionIndex: 15,
      name: "Stoats Nest Village",
      atcoCode: "490012821N",
      naptanCode: "74959",
      lat: 51.32329281782976,
      long: -0.12894517248952872,
    },
    {
      directionIndex: 16,
      name: "Windermere Road (CR5)",
      atcoCode: "490012207W2",
      naptanCode: "55107",
      lat: 51.326289716399764,
      long: -0.13021547105394757,
    },
    {
      directionIndex: 17,
      name: "Smitham Downs Road",
      atcoCode: "490012207N",
      naptanCode: "57007",
      lat: 51.32706241790856,
      long: -0.12960989905190537,
    },
    {
      directionIndex: 18,
      name: "Brighton Road / Old Lodge Lane",
      atcoCode: "490010565C",
      naptanCode: "72494",
      lat: 51.330854507270885,
      long: -0.12549367655075502,
    },
    {
      directionIndex: 19,
      name: "Grovelands Road (CR8)",
      atcoCode: "490007560N",
      naptanCode: "72620",
      lat: 51.33315434038545,
      long: -0.12310299872701487,
    },
    {
      directionIndex: 20,
      name: "Purley Tesco",
      atcoCode: "490011274W",
      naptanCode: "49515",
      lat: 51.33708744115034,
      long: -0.11882198022637702,
    },
    {
      directionIndex: 21,
      name: "Purley / Downlands Precinct",
      atcoCode: "490011259D",
      naptanCode: "72577",
      lat: 51.33916613697494,
      long: -0.1161094788008126,
    },
    {
      directionIndex: 22,
      name: "Christchurch Road / Purley Hospital",
      atcoCode: "490005236N",
      naptanCode: "56739",
      lat: 51.340668685031915,
      long: -0.11336295414011395,
    },
    {
      directionIndex: 23,
      name: "Montpelier Road",
      atcoCode: "490010002N",
      naptanCode: "75836",
      lat: 51.342981930881045,
      long: -0.10903218081920167,
    },
    {
      directionIndex: 24,
      name: "Brighton Road / Purley Downs Road",
      atcoCode: "490013543N",
      naptanCode: "57084",
      lat: 51.344430850509816,
      long: -0.10631611922886736,
    },
    {
      directionIndex: 25,
      name: "Biddulph Road",
      atcoCode: "490003117A",
      naptanCode: "47259",
      lat: 51.347115619653906,
      long: -0.10273035028946222,
    },
    {
      directionIndex: 26,
      name: "Purley Oaks Station",
      atcoCode: "490013603N",
      naptanCode: "72161",
      lat: 51.34871207615344,
      long: -0.10135761373387045,
    },
    {
      directionIndex: 27,
      name: "Sanderstead Road",
      atcoCode: "490011481N",
      naptanCode: "55005",
      lat: 51.352530979463424,
      long: -0.09839923208086615,
    },
    {
      directionIndex: 28,
      name: "South Croydon Bus Garage",
      atcoCode: "490012272N",
      naptanCode: "51918",
      lat: 51.35522000811111,
      long: -0.09785701081778764,
    },
    {
      directionIndex: 29,
      name: "Haling Park Road",
      atcoCode: "490007670N",
      naptanCode: "53151",
      lat: 51.358502493898705,
      long: -0.09786466885594337,
    },
    {
      directionIndex: 30,
      name: "Upland Road_ Croydon",
      atcoCode: "490013920N",
      naptanCode: "48829",
      lat: 51.36056558090294,
      long: -0.0980952093626671,
    },
    {
      directionIndex: 31,
      name: "Swan & Sugar Loaf",
      atcoCode: "490015548M",
      naptanCode: "75310",
      lat: 51.364482827545615,
      long: -0.09898159340191327,
    },
    {
      directionIndex: 32,
      name: "Coombe Road",
      atcoCode: "490005574V",
      naptanCode: "49076",
      lat: 51.3672452444837,
      long: -0.09908268495517072,
    },
    {
      directionIndex: 33,
      name: "Croydon Flyover",
      atcoCode: "490005855C18",
      naptanCode: "53549",
      lat: 51.37015759937346,
      long: -0.10011143666481502,
    },
    {
      directionIndex: 34,
      name: "Croydon Library",
      atcoCode: "490005858C7",
      naptanCode: "54065",
      lat: 51.37238657570445,
      long: -0.09892721117766692,
    },
    {
      directionIndex: 35,
      name: "Fairfield Halls",
      atcoCode: "490015223C10",
      naptanCode: "49565",
      lat: 51.37285672776532,
      long: -0.0968674859231117,
    },
    {
      directionIndex: 36,
      name: "East Croydon Rail Station",
      atcoCode: "490001089E3",
      naptanCode: "58093",
      lat: 51.37471376459074,
      long: -0.09161779657404094,
    },
    {
      directionIndex: 37,
      name: "Lebanon Road Tram Stop",
      atcoCode: "490002052E",
      naptanCode: "50721",
      lat: 51.37540361505078,
      long: -0.08539616729817129,
    },
    {
      directionIndex: 38,
      name: "Sandilands Tram Stop",
      atcoCode: "490002072B",
      naptanCode: "75849",
      lat: 51.37522641707531,
      long: -0.07896637043307597,
    },
    {
      directionIndex: 39,
      name: "Ashburton Road_ Addiscombe",
      atcoCode: "490003420E",
      naptanCode: "75083",
      lat: 51.375048999291835,
      long: -0.07582705645507773,
    },
    {
      directionIndex: 40,
      name: "Mapledale Avenue",
      atcoCode: "490009623E",
      naptanCode: "73220",
      lat: 51.37425002601977,
      long: -0.07098965689792905,
    },
    {
      directionIndex: 41,
      name: "Birch Tree Way",
      atcoCode: "490004014E",
      naptanCode: "50493",
      lat: 51.37521053341568,
      long: -0.06543168110765026,
    },
    {
      directionIndex: 42,
      name: "Trinity School_ Shirley",
      atcoCode: "490012099E1",
      naptanCode: "58911",
      lat: 51.37624371122168,
      long: -0.061019922327242786,
    },
    {
      directionIndex: 43,
      name: "Shirley Church Road",
      atcoCode: "490012094S",
      naptanCode: "56551",
      lat: 51.37300188276764,
      long: -0.055869443800720654,
    },
    {
      directionIndex: 44,
      name: "Shirley Windmill",
      atcoCode: "490012103S",
      naptanCode: "51473",
      lat: 51.370172597606896,
      long: -0.05445200158476126,
    },
    {
      directionIndex: 45,
      name: "Oaks Road",
      atcoCode: "490010505S",
      naptanCode: "49510",
      lat: 51.366979541852366,
      long: -0.05279156725840501,
    },
    {
      directionIndex: 46,
      name: "Pine Coombe",
      atcoCode: "490011005S",
      naptanCode: "76334",
      lat: 51.364263311381634,
      long: -0.051685728991154305,
    },
    {
      directionIndex: 47,
      name: "Coombe Lane Tram Stop",
      atcoCode: "490002024B",
      naptanCode: "77375",
      lat: 51.35930681362288,
      long: -0.05538646016271679,
    },
    {
      directionIndex: 48,
      name: "Heathfield Farm",
      atcoCode: "490007993S",
      naptanCode: "72148",
      lat: 51.35586361161493,
      long: -0.04949993290950811,
    },
    {
      directionIndex: 49,
      name: "Addington Park",
      atcoCode: "490003094E",
      naptanCode: "77066",
      lat: 51.353914212702264,
      long: -0.0377770632677609,
    },
  ],
};
